<template>
    <div>
        <div class="tab-bar">
            <div
                :class="{ active: checkedTabbar === 2 }"
                @click="checkedTabbar = 2"
            >
                下单得苹果
            </div>
            <div
                :class="{ active: checkedTabbar === 1 }"
                @click="checkedTabbar = 1"
            >
                指定行为得苹果
            </div>
        </div>
        <div class="config-panel">
            <template v-for="(action, key) in actionAppleConfig">
                <div
                    class="config-item"
                    :key="key"
                    v-show="checkedTabbar === action.methodType"
                >
                    <input
                        type="checkbox"
                        :value="true"
                        v-model="action.isEnable"
                        style="margin-bottom: 3px;"
                    />
                    {{ action.startText }}
                    <template v-if="action.methodType === 2">
                        <input
                            type="text"
                            placeholder="1-99"
                            v-model="action.value"
                            style="width: 50px; height: 30px; margin-left: 20px;"
                        />
                    </template>
                    <template v-else>
                        <CSSelect
                            style="margin-left: 20px;"
                        >
                            <select v-model="action.value">
                                <option value="">请选择</option>
                                <option v-for="n in 20" :key="n" :value="n">
                                    {{ n }}
                                </option>
                            </select>
                        </CSSelect>
                    </template>
                    {{ action.endText }}
                </div>
            </template>

            <div>
                <button class="btn btn-primary" style="width: 50px;height: 30px;padding: 0" @click="saveConfig">
                    保存
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { setAppleConfigUrl, getAppleConfigUrl } from "@/requestUrl";
import CSSelect from "@/components/common/CSSelect";
export default {
    name: "AppleProduceSettings",
    components: {
        CSSelect,
    },
    data() {
        return {
            checkedTabbar: 2,
            actionAppleConfig: {
                1: {
                    startText: "闪购付款后，产生的苹果数量为账单金额的倍数",
                    value: "",
                    isEnable: false,
                    methodType: 2,
                    endText: "倍",
                },
                2: {
                    startText: "企业服务付款后，产生的苹果数量为账单金额的倍数",
                    value: "",
                    isEnable: false,
                    methodType: 2,
                    endText: "倍",
                },
                3: {
                    startText: "上门服务付款后，产生的苹果数量为账单金额的倍数",
                    value: "",
                    isEnable: false,
                    methodType: 2,
                    endText: "倍",
                },
                4: {
                    startText: "会议室付款后，产生的苹果数量为账单金额的倍数",
                    value: "",
                    isEnable: false,
                    methodType: 2,
                    endText: "倍",
                },
                5: {
                    startText: "提交评价产生苹果数量",
                    value: "",
                    isEnable: false,
                    methodType: 1,
                    endText: "个",
                },
                6: {
                    startText: "首次发帖产生苹果数量",
                    value: "",
                    isEnable: false,
                    methodType: 1,
                    endText: "个",
                },
                7: {
                    startText: "首次回复产生苹果数量",
                    value: "",
                    isEnable: false,
                    methodType: 1,
                    endText: "个",
                },
                8: {
                    startText: "每日签到产生苹果数量",
                    value: "",
                    isEnable: false,
                    methodType: 1,
                    endText: "个",
                },
                9: {
                    startText: "提交问卷产生苹果数量",
                    value: "",
                    isEnable: false,
                    methodType: 1,
                    endText: "个",
                },
            },
        };
    },
    created() {
        this.getAppleConfig();
    },
    methods: {
        getAppleConfig() {
            this.$fly
                .get(getAppleConfigUrl, {
                    regionCode: this.$vc.getCurrentRegion().code,
                })
                .then((res) => {
                    if (res.code !== 0) {
                        return;
                    }
                    for (let key in this.actionAppleConfig) {
                        if (res.data.hasOwnProperty(key)) {
                            this.actionAppleConfig[key].value =
                                res.data[key].value;
                            this.actionAppleConfig[key].isEnable =
                                res.data[key].isEnable;
                        }
                    }
                });
        },
        // 触发保存操作
        async saveConfig() {
            let configItems = [],
                regionCode = this.$vc.getCurrentRegion().code;
            for (let action in this.actionAppleConfig) {
                if (this.actionAppleConfig.hasOwnProperty(action)) {
                    const config = this.actionAppleConfig[action];
                    if (config.value !== "") {
                        configItems.push({
                            regionCode,
                            actionType: Number(action),
                            value: config.value,
                            method: config.methodType,
                            enable: config.isEnable,
                        });
                    }
                }
            }
            this.setUpConfig(configItems);
        },
        // 设置苹果配置
        setUpConfig(items) {
            this.$fly
                .post(setAppleConfigUrl, {
                    items,
                })

                .then((res) => {
                    if (res.code !== 0) {
                        return;
                    }
                    this.$vc.toast("保存成功");
                });
        },
    },
};
</script>
<style lang="stylus" scoped>
.config-panel
    background #fff
    padding 20px 30px
    min-height 400px
    color #444
    box-shadow 0px 5px 10px 0px rgba(0, 0, 0, 0.1)
    .config-item
        display flex
        flex-flow row nowrap
        align-items center
        input
            &[type='checkbox']
                margin-right 10px
            &[type='text']
                height 30px
                padding 0 5px
                width 88px
                border-radius 3px
                border 1px solid #979797
                margin-right 4px
                margin-left 30px
        .cs-select
            margin-left 30px
            margin-right 4px
        &:not(:last-of-type)
            margin-bottom 10px
</style>
